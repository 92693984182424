import React from 'react';
import { useNavigate } from "react-router-dom";

const Completion = () => {
  const navigate = useNavigate();

  return (
    <div className="payment_complete_page">
      <h1>Transaction Completed</h1>
      <button type='button' onClick={() => navigate("/")}>Go Back</button>
    </div>
  );
};

export default Completion;
