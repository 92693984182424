import PropTypes from 'prop-types';

export default async function apiServicewt(quary) {
	const webLink = process.env.REACT_APP_WEB_LINK;
	const result = await fetch(webLink, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify(quary),
	});
	const responce = await result.json();
	if (responce.type === 'error') {
		// localStorage.removeItem('user-auth');
		// navigate('/');
		console.log('error');
	}
	return responce;
}

apiServicewt.propTypes = {
	quary: PropTypes.isRequired,
};
