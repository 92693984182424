import React from 'react';
import termsImage from '../../../assets/img/policy/term_img.png';

const Terms = () => {

	const items = [
		{
			title: 'Service Description',
			content: (
				<p className='text-justify'>
					SchedulerDesk acts as your go-to online platform for effortlessly booking
					appointments at a variety of salons and spas. Please note that while we
					facilitate these bookings, we do not own or manage any of the businesses listed
					on our platform.
				</p>
			),
		},
		{
			title: 'User Accounts',
			content: (
				<p className='text-justify'>
					Accessing SchedulerDesk requires creating an account. It's crucial to safeguard
					your account details and usage, including login credentials. You are solely
					responsible for all activities undertaken through your account.
				</p>
			),
		},
		{
			title: 'Appointment Bookings',
			content: (
				<p className='text-justify'>
					Whether it's a haircut, massage, or manicure, SchedulerDesk is here to help you
					schedule your desired treatments. Accuracy in providing information during
					booking is key, and remember to cancel any appointments you can't make. Some
					venues may require deposits or prepayments for specific services.
				</p>
			),
		},
		{
			title: 'Payment',
			content: (
				<p className='text-justify'>
					We're proud to offer SchedulerDesk's services to you free of charge. However,
					please be aware that certain establishments may request deposits or prepayments
					for select services. Any payment-related disputes should be handled directly
					with the salon in question.
				</p>
			),
		},
		{
			title: 'Cancellations and Refunds',
			content: (
				<p className='text-justify'>
					Should you need to cancel an appointment, please adhere to the salon's
					cancellation policy. While some venues may offer refunds or rescheduling options
					for canceled appointments, others may not. SchedulerDesk assumes no
					responsibility for refund requests or cancellations.
				</p>
			),
		},
		{
			title: 'Reviews and Ratings',
			content: (
				<p className='text-justify mb-4'>
					Your feedback matters! Feel free to leave honest and accurate reviews and
					ratings for salons and spas you've visited through SchedulerDesk. We reserve the
					right to remove any reviews or ratings deemed fraudulent or violating our
					content guidelines.
				</p>
			),
		},
		{
			title: 'Prohibited Conduct',
			content: (
				<p className='text-justify mb-4'>
					Please refrain from engaging in any illegal activities or behaviors that violate
					our terms of service while using SchedulerDesk. This includes impersonation,
					spamming, harassment, or any attempts to compromise the integrity of our system.
				</p>
			),
		},
		{
			title: 'Termination',
			content: (
				<p className='text-justify mb-4'>
					We reserve the right to terminate your account and access to SchedulerDesk at
					our discretion, without prior notice. Additionally, we may modify or discontinue
					our service without prior notification.
				</p>
			),
		},
		{
			title: 'Limitation of Liability',
			content: (
				<p className='text-justify mb-4'>
					While we strive to provide you with a seamless experience, SchedulerDesk cannot
					be held liable for any damages or losses resulting from your use of our service.
					This includes direct, indirect, incidental, consequential, or punitive damages,
					with our liability capped at the amount paid for our service.
				</p>
			),
		},
		{
			title: 'Governing Law',
			content: (
				<p className='text-justify mb-4'>
					These terms of service are governed by the laws of the jurisdiction where
					SchedulerDesk operates. Any legal actions related to these terms must be pursued
					in the appropriate court within that jurisdiction.
				</p>
			),
		},
	];
	return (
		<div className='container pb-3 mt-2'>
			<div className='card'>
				<img
					src={termsImage}
					className='card-img-top center'
					alt='image_privacy'
					style={{
						display: 'flex',
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
				/>
				<div className='card-body'>
					<p className='text-justify h4 px-2' style={{ textAlign: 'justify' }}>
						Welcome to SchedulerDesk! Before you dive into booking appointments at your
						favorite salons and spas through our platform, we kindly ask you to review
						our terms of service. By using our service, you agree to abide by these
						terms and conditions
					</p>

					<div className='justify-content-start mt-4 mb-5 b px-2'>
						<p className='justify-content-center'>Updated May 20, 2024</p>
					</div>
					<hr className='my-5 flex-grow-1' />

					<div className='px-2'>
						{items.map((item) => (
							<>
								<div className='d-flex flex-row'>
									<div>
									<h5>●</h5>
									</div>
									<div>
									<h5 className='px-2'>{item.title}</h5>
									</div>
									</div>
								<p className='px-4' style={{ textAlign: 'justify' }}>
									{item.content}
								</p>
							</>
						))}
					</div>
					<hr className='mt-5 flex-grow-1' />
					<div className='card-footer text-muted'>
						<p className='text-justify'>
							If you have any questions or concerns about our terms of service, please
							don't hesitate to contact us at{' '}
							<a href='mailto:support@schedulerdesk.com'>support@schedulerdesk.com</a>
							. We're here to help!.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Terms;
