import React from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import CheckoutForm from "../../../components/CheckoutForm";
import { PaymentContext } from "../../../contexts/PaymentContext"; 

// const stripePromise = loadStripe(
//   "pk_test_51OeCBjAau9CFzFsZ7pZh85uKL7EUpjXnltPoOYnPJN8c3u3M6FF847cf0S1Kkg4WAjLc5Sp5QnH7CFUw4dU5Bm9C004GmwrR3k"
// );

const Payment = () => {
  const { clientSecret } = useContext(PaymentContext);

  return (
    <div className="payment_container">
      <div className="payment_block">
        {/* {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )} */}
      </div>
    </div>
  );
};

export default Payment;
