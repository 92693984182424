import PropTypes from 'prop-types';

export default async function apiService(quary, token, setToken, navigate) {
	const webLink = process.env.REACT_APP_WEB_LINK;
	const result = await fetch(webLink, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'sdk-authorization': `${token.session_key}`,
		},
		body: JSON.stringify(quary),
	});
	const responce = await result.json();
	if (responce.type === 'error' && responce.message === 'Session expired') {
		setToken({});
		localStorage.removeItem('user-auth');
		navigate('/');
	}
	return responce;
}

apiService.propTypes = {
	quary: PropTypes.isRequired,
	token: PropTypes.string.isRequired,
	setToken: PropTypes.func.isRequired,
	navigator: PropTypes.isRequired,
};
