import React from 'react';
import { Route, Routes} from 'react-router-dom';
// import React, { lazy,  useContext, useState} from 'react';
// import { Route, Routes, Outlet, useNavigate,useLocation } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
// import Loader from '../../pages/common/vhelper/Loader';
// import { userAuth } from '../../contexts/userContext';

// const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

// const Test = () => {
	// return <div><h1>Forgot PW</h1></div>
	// const { token, setToken } = useContext(userAuth);
	// const navigate = useNavigate();
	// const location = useLocation();
	// const [loaderv, setLoaderv] = useState(false);

	// useEffect(() => {
	// 	let authcheck;
	// 	let authcheck2;

	// 	if (token.email) {
	// 		setLoaderv(false);
	// 		authcheck = setInterval(() => {
	// 			//console.log('Interval', token.session_key);
	// 			(async () => {
	// 				const web_link = process.env.REACT_APP_WEB_LINK;
	// 				//console.log('user token', token.session_key);
	// 				const data = { action: 'checkcuslog', email: token.email };
	// 				const res = await fetch(web_link, {
	// 					method: 'post',
	// 					headers: {
	// 						'Content-Type': 'application/json',
	// 						Accept: 'application/json',
	// 						'sdk-authorization': `${token.session_key}`,
	// 					},
	// 					body: JSON.stringify(data),
	// 				});
	// 				const responce = await res.json();
	// 				if (responce.type == 'error') {
	// 					setToken({});
	// 					localStorage.removeItem('user-auth');
	// 					//navigate('/');
	// 				}
	// 				//console.log('r login', responce);
	// 			})();
	// 		}, 1000 * 60);
	// 	} else {
	// 		setLoaderv(true);
	// 		authcheck2 = setTimeout(() => {
	// 			//console.log('Timeout', token.session_key);
	// 			(async () => {
	// 				const web_link = process.env.REACT_APP_WEB_LINK;
	// 				//console.log('user token', token.session_key);
	// 				const data = { action: 'checkcuslog', email: token.email };
	// 				//console.log('QQQQQQD');
	// 				const res = await fetch(web_link, {
	// 					method: 'post',
	// 					headers: {
	// 						'Content-Type': 'application/json',
	// 						Accept: 'application/json',
	// 						'sdk-authorization': `${token.session_key}`,
	// 					},
	// 					body: JSON.stringify(data),
	// 				}).catch(() => {
	// 					setToken({});
	// 					setLoaderv(false);
	// 					localStorage.removeItem('user-auth');
	// 					//navigate('/');
	// 				});

	// 				const responce = await res.json();
	// 				console.log('responce', responce);
	// 				if (responce.type == 'error') {
	// 					setToken({});
	// 					setLoaderv(false);
	// 					localStorage.removeItem('user-auth');
	// 					//navigate('/');
	// 				} else {
	// 					setLoaderv(false);
	// 				}
	// 				//console.log('r login', responce);
	// 			})();
	// 		}, 300);
	// 	}

	// 	return () => {
	// 		clearInterval(authcheck);
	// 		clearTimeout(authcheck2);
	// 	};
	// }, [token.email]);

	// // useEffect(() => {
	// // 	let cleartime;
	// // 	if (!token.email) {
	// // 		cleartime = setTimeout(() => {
	// // 			//navigate('/');
	// // 		}, 300);
	// // 	}
	// // 	return ()=>{
	// // 		clearTimeout(cleartime);
	// // 	}
	// // }, [token.email, location.pathname]);

	// if (loaderv) {
	// 	return <Loader />;
	// }

// 	return <Outlet />;
// };

const ContentRoutes = () => {
	return (
		<Routes>
			
				{contents.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={page.path} {...page} />
				))}
			
			{/* <Route path='*' element={<PAGE_404 />} /> */}
		</Routes>
	);
};

export default ContentRoutes;
