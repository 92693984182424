import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import privacyImage from '../../../assets/img/policy/privacy_img.png';

const Privacy = () => {
	const [value, setValue] = useState(0);

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};
	const textJustify = { textAlign: 'justify' };

	const items = [
		{
			title: 'Overview',
			content: (
				<>
					<p style={textJustify}>
						SchedulerDesk is an intuitive Web-Based Booking Application accompanied by
						two dedicated mobile apps, empowering you to effortlessly manage
						appointments even amidst client engagements or unavailability. The software
						facilitates seamless coordination for multiple workers, varied shifts,
						diverse services, including those with limited resources, and much more.
					</p>
					<p style={textJustify}>
						The SchedulerDesk customer mobile app is indispensable for small salon
						businesses, enhancing appointment management, customer convenience,
						operational efficiency, and ultimately fostering business growth.
					</p>
					<p style={textJustify}>
						Similarly, the SchedulerDesk employee application, "SchedulerDesk Mobile
						App," is a must-have for small salon businesses, optimizing appointment
						scheduling, enhancing customer experience, driving operational efficiency,
						and propelling overall business development.
					</p>
					<p style={textJustify}>
						Secure your White-label System today and unlock the potential for
						streamlined appointment management tailored to your business's unique needs.
					</p>
				</>
			),
		},
		{
			title: 'Information We Collect',
			content: (
				<p style={textJustify}>
					At SchedulerDesk, we gather personal data during your sign-up process, account
					creation, booking activities, and utilization of our services. This information
					encompasses your name, email address, phone number, payment details, and
					appointment particulars.
				</p>
			),
		},
		{
			title: 'How We Use Your Information',
			content: (
				<p style={textJustify}>
					Your personal data is utilized to furnish you with SchedulerDesk's array of
					services, facilitate booking procedures, process payments, engage in
					communication with you, and refine our service offerings. Additionally, we may
					employ your information to disseminate promotional offers and SchedulerDesk
					updates, with the option to opt-out at any time.
				</p>
			),
		},
		{
			title: 'Sharing Your Information',
			content: (
				<p style={textJustify}>
					In certain instances, we may disclose your personal data to third-party service
					providers integral to delivering our services. These providers are bound by our
					privacy protocols and are prohibited from exploiting your data for alternative
					purposes. Moreover, we may divulge your information when legally obligated, such
					as in compliance with judicial orders or legal proceedings.
				</p>
			),
		},
		{
			title: 'Data Security',
			content: (
				<p style={textJustify}>
					Maintaining the integrity of your personal data is paramount to us.
					SchedulerDesk employs prudent measures to thwart unauthorized access,
					disclosure, alteration, and destruction of your information. Our utilization of
					industry-standard security technologies and protocols fortifies your data
					against potential threats. However, it's important to note that no method of
					electronic data transmission is entirely infallible, and absolute security
					cannot be guaranteed.
				</p>
			),
		},
		{
			title: 'Data Retention',
			content: (
				<p style={textJustify}>
					Your personal data will be retained for the duration necessary to furnish
					SchedulerDesk services, adhere to legal obligations, resolve disputes, and
					enforce contractual agreements.
				</p>
			),
		},
		{
			title: 'Changes to this Policy',
			content: (
				<p style={textJustify}>
					Periodically, we may revise this Privacy Policy. Should any substantial
					modifications be made, you will be promptly notified via email or through the
					posting of the updated policy on our website.
				</p>
			),
		},
		{
			title: 'Documentation',
			content: (
				<>
					<h5>● Rewards & Referrals for leads</h5>
					<p style={textJustify}>
						Referral marketing platforms allow your business to track and target your
						most loyal, valued customers for rewards and provide a more personalized
						interaction experience. Scheduler Desk helps customers know your brand story
						and improve your overall reputation.
					</p>

					<h5>● Automated Emails/Mass E-mail</h5>
					<p style={textJustify}>
						Availability of bulk e-mailing with 1000+ clients, SchedulerDesk provides
						access to track sent e-mails, Opened mails, buttons or links clicked, and
						other activities around those mails.
					</p>

					<h5>● Monitor Employees, Update Work Scheduler</h5>
					<p style={textJustify}>
						By tracking working hours and completed working schedules, management can
						identify gaps and disadvantages. Scheduler Desk can use to analyze their
						employees and work activities as well.
					</p>

					<h5>● Customer Handle</h5>
					<p style={textJustify}>
						Maintaining a relationship between Business to Customer is needed. Gather
						details like Name, DOB, E-mail and Mobile Number according to GDPR rules
						business can highlight them by sending Offers, Promotions and other Valuable
						information to them.
					</p>

					<h5>● Quick Reports, Easy to Use</h5>
					<p style={textJustify}>
						Reports are needed to monitor the performance of respective employees,
						business decisions, and the whole business. Generally marketing strategies,
						Business Analysis, and other decision-making based on these reports.
					</p>

					<h5>● Manage Business, Updates</h5>
					<p style={textJustify}>
						Quick updates on bookings are most important. With few guidelines, anyone
						can understand the main functionalities of this Scheduler Desk. Can serve
						the customer with accurate updates and keep them in touch with your business
						for real.
					</p>

					<h5>● Quick Profiles</h5>
					<p style={textJustify}>
						Scheduler Desk directly shows co-workers’ profiles and their assigned tasks
						and pending time slots for getting a quick update.
					</p>

					<h5>● Ease of Appointment Handling</h5>
					<p style={textJustify}>
						Scheduler Desk can reserve respective time slots, dates based on customer
						requirements. It’ll notify both you and the customer.
					</p>
				</>
			),
		},
		{
			title: 'Contact Us',
			content: (
				<>
					<p style={textJustify}>
						For queries or concerns regarding this Privacy Policy or SchedulerDesk's
						handling of your personal data, please don't hesitate to reach out to us at{' '}
						<a href='mailto:support@schedulerdesk.com'>support@schedulerdesk.com</a> .
						Your usage of SchedulerDesk signifies your acceptance of the terms outlined
						in this Privacy Policy.
					</p>

					<h5>Location:</h5>
					<p>
						Head Office: No 21/8B, 3/1, Chandra de Silva Mawatha, Nugegoda, Sri Lanka.
						10250
					</p>

					<h5>Email:</h5>
					<p>support@schedulerdesk.com</p>

					<h5>Phone: </h5>
					<p>+94 112 099 298</p>
				</>
			),
		},
	];

	return (
		<div className='container pb-5 mt-2'>
			<div className='card'>
				<img
					src={privacyImage}
					className='card-img-top center'
					alt='image_privacy'
					style={{
						display: 'flex',
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
				/>
				<div className='card-body'>
					<p className='text-justify h4 px-1'>
						Thank you for your interest in SchedulerDesk, the premier salon booking
						system committed to safeguarding your privacy. Our Privacy Policy serves to
						elucidate how SchedulerDesk gathers, employs, and safeguards your personal
						data to ensure your peace of mind.
					</p>

					<div className='justify-content-start mt-4 mb-5 b px-1'>
						{
							//<h1>SchedulerDesk Privacy Policy</h1>
						}
						<p className='justify-content-center'>Effective March 28, 2024</p>
					</div>
					<hr className='my-5 flex-grow-1' />

					<div className='row'>
						<Tabs
							orientation='vertical'
							value={value}
							onChange={handleChange}
							sx={{
								'& .MuiTabs-indicator': {
									backgroundColor: '#4d69fa',
									height: 3,
								},
							}}
							className='col-3'>
							{items.map((item, index) => (
								<Tab
									label={item.title}
									key={index}
									sx={{
										borderRight: 1,
										borderColor: 'divider',
										alignItems: 'start',
										textAlign: 'start',
										color: '#e7eef8',
										'&.Mui-selected': {
											color: '#4d69fa',
										},
									}}
								/>
							))}
						</Tabs>
						<div className='col-9'>{items[value].content}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Privacy;
