import React, { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import '../../presentation/auth/Login.css';
import Icon from '../../../components/icon/Icon';
// include styles
import 'rodal/lib/rodal.css';
import { Ralert } from '../vhelper/Normarodallalert';
import apiServicewt from '../../../api/apiServicewt';
// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Change Your Password</div>
			<br />
			<br />
			<div>Enter Password below and we’ll reset your password</div>
			<br />
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();

	const [isNewUser] = useState(isSignUp);

	const navigate = useNavigate();

	const lpasswordRef = useRef();
	const cpasswordRef = useRef();

	const [loadalert, setLoadalert] = useState(false);
	const [msgloadalert, setMsgLoadalert] = useState('');
	const [msgtypeloadalert, setMsgtypeLoadalert] = useState('');

	const [isemptypw, setIsemptypw] = useState({ error: false, msg: '*Required' });
	const [isemptycpw, setIsemptycpw] = useState({ error: false, msg: '*Required' });

	const closemodel = () => {
		setLoadalert(false);
	};
	const startmodel = () => {
		setLoadalert(true);
	};

	// cpw show
	const [cshowPassword, setCshowPassword] = useState(false);

	const ctogglePasswordVisibility = () => {
		setCshowPassword(!cshowPassword);
	};

	// pw show
	const [lshowPassword, setLshowPassword] = useState(false);

	const ltogglePasswordVisibility = () => {
		setLshowPassword(!lshowPassword);
	};

	const location = useLocation();

	// PW Change

	const pwchange = async () => {
		let cansendapi = true;
		const minLengthRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*])[A-Za-z\d.!@#$%^&*]{8,}$/;
		if (!lpasswordRef.current.value) {
			setIsemptypw({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (minLengthRegex.test(`${lpasswordRef.current.value}`.trim())) {
			setIsemptypw({ error: false });
		} else {
			setIsemptypw({ error: true, msg: 'Must contain at least 8 characters,including UPPER/lowercase,symbles and numbers' });
			cansendapi = false;
		}
		// if (!lpasswordRef.current.value) {
		// 	setIsemptypw({ error: true, msg: '*Required' });
		// 	cansendapi = false;
		// } else {
		// 	setIsemptypw({ error: false });
		// }
		if (!cpasswordRef.current.value) {
			setIsemptycpw({ error: true, msg: '*Required' });
			cansendapi = false;
		}  else if (minLengthRegex.test(`${cpasswordRef.current.value}`.trim())) {
			setIsemptycpw({ error: false });
		} else {
			setIsemptycpw({ error: true, msg: 'Must contain at least 8 characters,including UPPER/lowercase,symbles and numbers' });
			cansendapi = false;
		}

		if (cansendapi) {
			const item = {
				action: 'reset_password',
				cust_id: location.state.cust_id,
				hash_code: location.state.hash_code,
				password: lpasswordRef.current.value,
				confirm_password: cpasswordRef.current.value,
			};
			const flechdata = await apiServicewt(item, navigate);

			if (flechdata.type === 'Success' || flechdata.type === 'success') {
				navigate('/login');
			} else {
				startmodel();
				setMsgLoadalert(flechdata.message);
				setMsgtypeLoadalert(flechdata.type);
			}
		}
	};

	return (
		<>
			<Ralert
				msgloadalert={msgloadalert}
				msgtypeloadalert={msgtypeloadalert}
				loadalert={loadalert}
				closemodel={closemodel}
			/>
			<div className='bg-img'>
				<PageWrapper title={'Login'}>
					<Page className='p-0'>
						<div className='row h-100 align-items-center justify-content-center'>
							<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
								<br />
								<br />
								<br />
								<Card className='shadow-3d-dark' data-tour='login-page'>
									<CardBody
										style={{
											justifyContent: 'center',
										}}>
										<div className='text-center my-3'>
											<Link
												to='/'
												className={classNames(
													'text-decoration-none  fw-bold display-2',
													{
														'text-dark': !darkModeStatus,
														'text-light': darkModeStatus,
													},
												)}>
												<Logo width={200} />
											</Link>
										</div>

										<LoginHeader isNewUser={isNewUser} />

										<form className='row g-4'>
											<div className='col-12'>
												<FormGroup isFloating label='New Password'>
													<Input
														type={lshowPassword ? 'text' : 'password'}
														ref={lpasswordRef}
													/>
													<button
														type='button'
														onClick={ltogglePasswordVisibility}
														style={{
															position: 'absolute',
															right: '10px', // Adjust the right position as needed
															top: '50%',
															transform: 'translateY(-50%)',
															border: 'none',
															background: 'none',
															cursor: 'pointer',
														}}>
														{lshowPassword ? (
															<Icon
																icon='Visibility'
																size='2x'
																color='white'
															/>
														) : (
															<Icon
																icon='VisibilityOff'
																size='2x'
																color='white'
															/>
														)}{' '}
													</button>
												</FormGroup>
												{isemptypw.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{' '}
														{isemptypw.msg
															? isemptypw.msg
															: '*Required'}
													</p>
												) : (
													<br />
												)}
											</div>
											<div className='col-12'>
												<FormGroup isFloating label='Repeat Password'>
													<Input
														type={cshowPassword ? 'text' : 'password'}
														ref={cpasswordRef}
													/>
													<button
														type='button'
														onClick={ctogglePasswordVisibility}
														style={{
															position: 'absolute',
															right: '10px', // Adjust the right position as needed
															top: '50%',
															transform: 'translateY(-50%)',
															border: 'none',
															background: 'none',
															cursor: 'pointer',
														}}>
														{cshowPassword ? (
															<Icon
																icon='Visibility'
																size='2x'
																color='white'
															/>
														) : (
															<Icon
																icon='VisibilityOff'
																size='2x'
																color='white'
															/>
														)}{' '}
													</button>
												</FormGroup>
												{isemptycpw.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{' '}
														{isemptycpw.msg
															? isemptycpw.msg
															: '*Required'}
													</p>
												) : (
													<br />
												)}
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={() => pwchange()}>
													Submit
												</Button>
											</div>
										</form>
									</CardBody>
								</Card>
							</div>
						</div>
					</Page>
				</PageWrapper>
			</div>
		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
