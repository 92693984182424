import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import './styles/styles.scss';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import UserContextProvider from './contexts/userContext';
import SerempContextProvider from './contexts/serempContext';
import PaymentContextProvider from './contexts/PaymentContext';
import { CartProvider } from './contexts/CartContext';

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<UserContextProvider>
					<SerempContextProvider>
						<PaymentContextProvider>
							<CartProvider>
							<App />
							</CartProvider>
						</PaymentContextProvider>
					</SerempContextProvider>
				</UserContextProvider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
