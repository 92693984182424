import React, { createContext, useState, useMemo, useEffect } from 'react';

export const CartContext = createContext(null);

export const CartProvider = ({ children }) => {
	const [itemsCount, setItemsCount] = useState(null);
	const [itemsCountstart, setItemsCountstart] = useState(null);
	const [cartitemcount, setCartitemcount] = useState(0);
	const [key, setKey] = useState(0);

	useEffect(() => {
		const ldata = localStorage.getItem('sdcart_CartContext')
		
        if(ldata!==null){
			const data= JSON.parse(ldata)
			console.log("sdcart",data)
			setItemsCount(data.itemsCount)
			setItemsCountstart(data.itemsCountstart)
			setCartitemcount(data.cartitemcount)
			setKey(data.key)
		}
	}, []);

	useEffect(() => {
		if(itemsCount!==null && itemsCountstart!==null){

			const data = { itemsCount, itemsCountstart, cartitemcount, key };
			localStorage.setItem('sdcart_CartContext', JSON.stringify(data));
		}
	}, [itemsCount, itemsCountstart, cartitemcount, key]);

	const values = useMemo(
		() => ({
			itemsCount,
			setItemsCount,
			itemsCountstart,
			setItemsCountstart,
			cartitemcount,
			setCartitemcount,
			key,
			setKey,
		}),
		[itemsCount, itemsCountstart, cartitemcount, key],
	);

	return <CartContext.Provider value={values}>{children}</CartContext.Provider>;
};
