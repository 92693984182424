import React, { createContext, useState } from 'react';
import { useMemo } from 'react';

export const serEmp = createContext({});

const SerempContextProvider = ({ children }) => {
	const [serviceidcont, setServiceidcont] = useState("");
	const values = useMemo(
		() => ({
			serviceidcont,
			setServiceidcont,
		}),
		[serviceidcont],
	);
	return <serEmp.Provider value={values}>{children}</serEmp.Provider>;
};

export default SerempContextProvider;
